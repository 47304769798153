@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.dark-reader-guard {
    isolation: isolate;
    forced-color-adjust: none;
  }